import {
  CreatePhotoAlbumCommentRequest,
  DeletePhotoAlbumCommentRequest,
  UpdatePhotoAlbumCommentRequest,
} from "../infra/api/pb/public_photo_album_comment_pb";
import {
  PublicPhotoAlbumCommentCommand,
  PublicPhotoAlbumCommentCommandCreate,
  PublicPhotoAlbumCommentCommandDelete,
  PublicPhotoAlbumCommentCommandUpdate,
  PublicPhotoAlbumCommentQuery,
  PublicPhotoAlbumCommentQueryGetAllByPhotoAlbum,
} from "../infra/api/pb/public_photo_album_comment_pb_service";
import { GRPCUnary } from "../infra/api/client";
import { Empty, PhotoAlbumID } from "../infra/api/pb/common_pb";
import { PhotoAlbumCommentList } from "../infra/api/pb/entity_photo_album_comment_pb";
import * as firebase from "firebase/app";
import "firebase/auth";

interface ICreate {
  params: CreatePhotoAlbumCommentRequest;
}

interface IUpdate {
  params: UpdatePhotoAlbumCommentRequest;
}

interface IDelete {
  params: DeletePhotoAlbumCommentRequest;
}

interface IPhotoAlbumCommentHooks {
  getAllByPhotoAlbum: (photoAlbumId: string) => Promise<PhotoAlbumCommentList>;

  createPhotoAlbumComment: (req: ICreate) => Promise<void>;
  updatePhotoAlbumComment: (req: IUpdate) => Promise<void>;
  deletePhotoAlbumComment: (req: IDelete) => Promise<void>;
}

const usePhotoAlbumCommentHooks = (): IPhotoAlbumCommentHooks => {
  const getAllByPhotoAlbum = async (photoAlbumId: string) => {
    try {
      const req: PhotoAlbumID = new PhotoAlbumID();
      req.setId(photoAlbumId);
      const res = await GRPCUnary<
        PhotoAlbumID,
        PhotoAlbumCommentList,
        PublicPhotoAlbumCommentQueryGetAllByPhotoAlbum
      >({
        method: PublicPhotoAlbumCommentQuery.GetAllByPhotoAlbum,
        request: req,
      });

      return res;
    } catch (e) {
      throw e;
    }
  };

  const createPhotoAlbumComment = async (req: ICreate) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        CreatePhotoAlbumCommentRequest,
        Empty,
        PublicPhotoAlbumCommentCommandCreate
      >({
        method: PublicPhotoAlbumCommentCommand.Create,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  const updatePhotoAlbumComment = async (req: IUpdate) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        UpdatePhotoAlbumCommentRequest,
        Empty,
        PublicPhotoAlbumCommentCommandUpdate
      >({
        method: PublicPhotoAlbumCommentCommand.Update,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  const deletePhotoAlbumComment = async (req: IDelete) => {
    try {
      const token = await firebase.auth().currentUser?.getIdToken();

      await GRPCUnary<
        DeletePhotoAlbumCommentRequest,
        Empty,
        PublicPhotoAlbumCommentCommandDelete
      >({
        method: PublicPhotoAlbumCommentCommand.Delete,
        token: token,
        request: req.params,
      });
    } catch (e) {
      throw e;
    }
  };

  return {
    getAllByPhotoAlbum,
    createPhotoAlbumComment,
    updatePhotoAlbumComment,
    deletePhotoAlbumComment,
  };
};

export { usePhotoAlbumCommentHooks };
