// package: service
// file: public_photo_album_comment.proto

var public_photo_album_comment_pb = require("./public_photo_album_comment_pb");
var common_pb = require("./common_pb");
var entity_photo_album_comment_pb = require("./entity_photo_album_comment_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var PublicPhotoAlbumCommentQuery = (function () {
  function PublicPhotoAlbumCommentQuery() {}
  PublicPhotoAlbumCommentQuery.serviceName = "service.PublicPhotoAlbumCommentQuery";
  return PublicPhotoAlbumCommentQuery;
}());

PublicPhotoAlbumCommentQuery.GetAllByPhotoAlbum = {
  methodName: "GetAllByPhotoAlbum",
  service: PublicPhotoAlbumCommentQuery,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.PhotoAlbumID,
  responseType: entity_photo_album_comment_pb.PhotoAlbumCommentList
};

exports.PublicPhotoAlbumCommentQuery = PublicPhotoAlbumCommentQuery;

function PublicPhotoAlbumCommentQueryClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicPhotoAlbumCommentQueryClient.prototype.getAllByPhotoAlbum = function getAllByPhotoAlbum(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicPhotoAlbumCommentQuery.GetAllByPhotoAlbum, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicPhotoAlbumCommentQueryClient = PublicPhotoAlbumCommentQueryClient;

var PublicPhotoAlbumCommentCommand = (function () {
  function PublicPhotoAlbumCommentCommand() {}
  PublicPhotoAlbumCommentCommand.serviceName = "service.PublicPhotoAlbumCommentCommand";
  return PublicPhotoAlbumCommentCommand;
}());

PublicPhotoAlbumCommentCommand.Create = {
  methodName: "Create",
  service: PublicPhotoAlbumCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_photo_album_comment_pb.CreatePhotoAlbumCommentRequest,
  responseType: common_pb.Empty
};

PublicPhotoAlbumCommentCommand.Update = {
  methodName: "Update",
  service: PublicPhotoAlbumCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_photo_album_comment_pb.UpdatePhotoAlbumCommentRequest,
  responseType: common_pb.Empty
};

PublicPhotoAlbumCommentCommand.Delete = {
  methodName: "Delete",
  service: PublicPhotoAlbumCommentCommand,
  requestStream: false,
  responseStream: false,
  requestType: public_photo_album_comment_pb.DeletePhotoAlbumCommentRequest,
  responseType: common_pb.Empty
};

exports.PublicPhotoAlbumCommentCommand = PublicPhotoAlbumCommentCommand;

function PublicPhotoAlbumCommentCommandClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

PublicPhotoAlbumCommentCommandClient.prototype.create = function create(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicPhotoAlbumCommentCommand.Create, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicPhotoAlbumCommentCommandClient.prototype.update = function update(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicPhotoAlbumCommentCommand.Update, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

PublicPhotoAlbumCommentCommandClient.prototype.delete = function pb_delete(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(PublicPhotoAlbumCommentCommand.Delete, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.PublicPhotoAlbumCommentCommandClient = PublicPhotoAlbumCommentCommandClient;

