import * as React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { PhotoPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { theme } from "../components/theme";
import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { CloudinaryPath } from "../infra/clludinary/client";
import MarkdownViewer from "../components/MarkDownViwer";
import { PhotoAlbumCommentList } from "../infra/api/pb/entity_photo_album_comment_pb";
import { useContext, useEffect, useState } from "react";
import { useAuthHooks } from "../hooks/auth_hooks";
import { usePhotoAlbumCommentHooks } from "../hooks/photo_album_comment_hooks";
import { AuthContext } from "../context/auth_context";
import { Log } from "../util/Logger";
import { useForm } from "react-hook-form";
import {
  CreateBlogPostCommentRequest,
  DeleteBlogPostCommentRequest,
} from "../infra/api/pb/public_blog_post_comment_pb";
import { CreatePhotoAlbumCommentRequest } from "../infra/api/pb/public_photo_album_comment_pb";

dayjs.extend(relativeTime);

type FormType = {
  comment: string;
};

interface PhotoProps {
  data: PhotoPageQuery;
  pageContext: {
    contentful_id: string;
    slug: string;
    next: {
      contentful_id: string;
      slug: string;
      title: string;
    };
    previous: {
      contentful_id: string;
      slug: string;
      title: string;
    };
  };
}

const PhotoAlbum: React.FC<PhotoProps> = ({ data, pageContext }) => {
  const photoAlbum = data.allContentfulPhotoAlbum.edges[0].node;
  const photoAlbumId = photoAlbum.contentful_id;

  const [photoAlbumCommentList, setPhotoAlbumCommentList] = useState<
    PhotoAlbumCommentList
  >();

  const { loginGithub, logout } = useAuthHooks();
  const {
    getAllByPhotoAlbum,
    createPhotoAlbumComment,
    updatePhotoAlbumComment,
    deletePhotoAlbumComment,
  } = usePhotoAlbumCommentHooks();

  const { user } = useContext(AuthContext);

  const initData = async () => {
    try {
      const raw = await getAllByPhotoAlbum(photoAlbumId);
      setPhotoAlbumCommentList(raw);
    } catch (e) {
      Log.captureException(e);
    }
  };

  const { register, handleSubmit, watch, errors, setValue, reset } = useForm<
    FormType
  >();

  const onSubmit = async (data: FormType, e: any) => {
    const params = new CreatePhotoAlbumCommentRequest();
    params.setPhotoAlbumId(photoAlbumId);
    params.setBody(data.comment);
    params.setUsername(user.email);
    await createPhotoAlbumComment({ params });
    await initData();
    reset();
  };

  const handlerOnLoginGithub = async () => {
    try {
      await loginGithub();
    } catch (e) {
      Log.captureException(e);
    }
  };

  const handlerOnDelete = async (id: string) => {
    try {
      const params = new DeleteBlogPostCommentRequest();
      params.setId(id);
      await deletePhotoAlbumComment({ params });
      await initData();
    } catch (e) {
      Log.captureException(e);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await initData();
      } catch (e) {
        Log.captureException(e);
      }
    })();
  }, [photoAlbumId]);

  return (
    <Layout>
      <Helmet>
        <title>{photoAlbum.title}</title>
      </Helmet>

      <Container>
        <Title>{photoAlbum.title}</Title>

        <EntryHeaderMeta style={{ marginBottom: "12px" }}>
          <Time>{photoAlbum.updatedAt}</Time>
          <ByLine> / </ByLine>
          <UpperCaseAutherName>{photoAlbum.refAuthor.name}</UpperCaseAutherName>
        </EntryHeaderMeta>

        <div style={{ marginBottom: "12px" }}>
          <MarkdownViewer source={photoAlbum?.description?.description || ""} />
        </div>

        <PhotosContainer>
          {photoAlbum.photos.map((item, index) => {
            const p = CloudinaryPath({
              url: item.secure_url,
              rawTransformation: item.raw_transformation,
            });
            return (
              <div key={item.id}>
                <img
                  src={p.thumbnail}
                  srcSet={`${p.sp} 725w, ${p.pc} 920w`}
                  alt="image"
                />
              </div>
            );
          })}
        </PhotosContainer>

        <CommentContent>
          <h2>コメント</h2>

          {!user && (
            <CommentAuthContainer>
              <LoginButton onClick={() => handlerOnLoginGithub()}>
                Login
              </LoginButton>
            </CommentAuthContainer>
          )}
          {user && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Textarea name="comment" ref={register} />
              <FlexLeftWrapper>
                <CommentButton
                  id={`button-add-photo-album-comment`}
                  type="submit"
                >
                  AddComment
                </CommentButton>
              </FlexLeftWrapper>
            </form>
          )}
          <div>
            {photoAlbumCommentList &&
              photoAlbumCommentList.getItemsList().map((comment) => {
                return (
                  <CommentWrapper key={comment.getId()}>
                    <NameAndDaysWrapper>
                      <CommentName>{comment.getUsername()}</CommentName>
                      <CommentCreatedDay>
                        {dayjs().to(dayjs(comment.getCreatedat()))}
                      </CommentCreatedDay>
                    </NameAndDaysWrapper>
                    <CommentBody>{comment.getBody()}</CommentBody>

                    {user && user.id === comment.getUserid() && (
                      <CommentFooter>
                        <CommentButton
                          id={`button-delete-photo-album-comment`}
                          onClick={() => handlerOnDelete(comment.getId())}
                        >
                          Delete
                        </CommentButton>
                      </CommentFooter>
                    )}
                  </CommentWrapper>
                );
              })}
          </div>
        </CommentContent>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query PhotoPage($contentful_id: String) {
    allContentfulPhotoAlbum(filter: { contentful_id: { eq: $contentful_id } }) {
      edges {
        node {
          contentful_id
          heroImage {
            url
            secure_url
          }
          title
          slug
          description {
            description
          }
          refAuthor {
            avatorImage {
              file {
                url
              }
            }
            name
          }
          photos {
            id
            url
            secure_url
            raw_transformation
          }
          updatedAt(formatString: "YYYY年MM月DD日")
        }
      }
    }
  }
`;

export default PhotoAlbum;

const Container = styled.div`
  color: #000000;
  width: 100vw;
  padding: 0 10px;
  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 0 auto;
    padding: 40px 15px 80px;
    width: 1200px;
  }
  * {
    box-sizing: border-box;
  }
`;

const Title = styled.h1`
  font-size: 21px;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    font-size: 40px;
    line-height: 48px;
  }
`;

const PhotosContainer = styled.div`
  width: 100%;

  & > div {
    width: 100%;

    img {
      width: 100%;
    }
  }
`;

const EntryHeaderMeta = styled.div`
  text-transform: uppercase;
`;

const Time = styled.time`
  color: #999;
`;

const ByLine = styled.span`
  color: #999;
  margin: 0 8px;
`;

const UpperCaseAutherName = styled.span`
  color: #999;
  text-transform: uppercase;
`;

const CommentContent = styled.div`
  margin-top: 40px;
`;

const Textarea = styled.textarea`
  display: inline-block;
  white-space: pre-wrap;
  padding: 8px;
  outline: 0;
  overflow: auto;
  min-height: 130px;
  width: 100%;
`;

const FlexLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CommentButton = styled.button`
  background: #1971c2;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  padding: 6px 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;
  border-radius: 3px;
  color: #fff;
  margin-left: 5px;
  margin-right: 5px;
`;

const CommentWrapper = styled.div`
  padding: 12px 0 0 12px;
  margin-top: 16px;
  border-top: 1px solid #f0f0f0;

  border-left: 2px solid rgb(57, 106, 177);
`;

const CommentBody = styled.p`
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 48px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const NameAndDaysWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CommentName = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: #555;
  border: none;
  display: table;
  z-index: 1;
  /* margin-left: 48px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  /* cursor: pointer; */
`;

const CommentCreatedDay = styled.div`
  display: inline;
  color: #888;
  font-size: 12px;
`;

const CommentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CommentAuthContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LoginButton = styled.div`
  text-align: right;
  margin-right: 16px;
  height: 38px;
  color: #868e96;
  font-weight: 700;
  cursor: pointer;
  display: block;
`;
