import {
  CLOUDINARY_PC,
  CLOUDINARY_SP,
  CLOUDINARY_THUMBNAIL,
} from "../../config/constans";

export type CloudinaryPathType = {
  thumbnail: string;
  sp: string;
  pc: string;
};

export interface ICloudinaryPath {
  url: string;
  rawTransformation: string;
}

export const CloudinaryPath = (req: ICloudinaryPath): CloudinaryPathType => {
  try {
    return {
      thumbnail:
        req.url.indexOf(req.rawTransformation) > -1
          ? req.url.replace(req.rawTransformation, CLOUDINARY_THUMBNAIL)
          : req.url,
      sp:
        req.url.indexOf(req.rawTransformation) > -1
          ? req.url.replace(req.rawTransformation, CLOUDINARY_SP)
          : req.url,
      pc:
        req.url.indexOf(req.rawTransformation) > -1
          ? req.url.replace(req.rawTransformation, CLOUDINARY_PC)
          : req.url,
    };
  } catch (e) {
    throw e;
  }
};
